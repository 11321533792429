
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

/* Default theme. ~960B */
@import '~@vime/core/themes/default.css';

/* Optional light theme (extends default). ~400B */
@import '~@vime/core/themes/light.css';

@import '~animate.css/animate.min';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$keseco-angular-primary: mat-palette($mat-indigo);
$keseco-angular-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$keseco-angular-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$keseco-angular-theme: mat-light-theme((
  color: (
    primary: $keseco-angular-primary,
    accent: $keseco-angular-accent,
    warn: $keseco-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($keseco-angular-theme);

/* You can add global styles to this file, and also import other style files */

html, body { 
  margin: 0;
  height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}

.title_div{
  text-align: center;  
  margin-bottom: 50px;
}

h1{
  font-size: 50px;
  line-height: 100%;
}

h3{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
}

a{
  font-size: 15px;
  color: darkgreen;
}

.text-div{
  align-items: left;
  justify-content: left;
  
}

.dark-div{
  background-color: rgb(199, 249, 250);
  color: rgb(33, 151, 191);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.light-div{
  background-color: rgb(33, 151, 191);
  color: rgb(199, 249, 250);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px;
  padding-top: 25px;
  padding-bottom: 25px;
}

img{
  margin: 15px;
}

@media only screen and (max-width: 600px) {
  .landing-video{
    width: 100%;
    margin-left: 0;
    padding: 0;
    border-style: hidden;
    left: 0;
    pointer-events: none;
  }
  .first-div{
    padding: 0;
    padding-top: 10px;
    margin-top: 60%;
  }
  .dark-div{
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    padding-top: 5px;
    display:block;
    align-items: left;
    justify-content: left;
  }
  .light-div{
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    padding-top: 5px;
    display:block;
    align-items: left;
    justify-content: left;
  }
  .div-img{
    width: 80%;
  }
  .text-div{
    display: block;
    align-items: left;
    justify-content: left;
  }
}
